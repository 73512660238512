import { IResourceComponentsProps, useApiUrl, useModal, useNotification } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  DeleteButton,
  ShowButton,
  Icons,
  Button,
  Card,
  Form,
  Select,
  useSelect,
  Modal,
} from "@pankod/refine-antd";
import { BranchOffice, IBranchOfficesSearch, ILinkedPlaces } from "interfaces";
import { BFF_KEY } from "environment";
import { useState } from "react";
import { ModalBranchOfficeMunicipiesBind } from "components/modals";

export const BranchMunicipalitiesList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<ILinkedPlaces>({
    initialCurrent: 1,
    initialPageSize: 10,
    hasPagination: true,
    onSearch: (values: any) => {
      return [
        {
          field: "id_ob",
          operator: "eq",
          value: values.branch_id,
        }
      ];
    },
  });
  const [branchOffice, setBranchOffice] = useState<BranchOffice>();

  const { selectProps } = useSelect<IBranchOfficesSearch>({
    resource: "v1/branch-office/distinct-ids",
    optionLabel: "branch_id",
    optionValue: "branch_id",
    hasPagination: false
  });

  const apiUrl = useApiUrl();
  const toast = useNotification();

  const {
    show: branchOfficesBindShow,
    visible: branchOfficesBindVisible,
    close: branchOfficesBindClose
  } = useModal();

  function showModal(branch: BranchOffice) {
    setBranchOffice(branch);
    branchOfficesBindShow();
  }

  async function handleUnbindFilial(municipieId: number) {
    await fetch(`${apiUrl}/v1/branch-office-place/${municipieId}/update-areaid/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'ocp-apim-subscription-key': BFF_KEY
      },
    }).then(() => {
      toast.open?.({
        message: 'filial desvinculada ao municipio com sucesso.',
        type: 'success',
    });
    }).catch(() => {
      toast.open?.({
        message: 'ocorreu um erro ao tentar desvincular o municipio a filial selecionada, tente novamente mais tarde.',
        type: 'error',
    });
    }).finally(() => {
      tableQueryResult.refetch();
    });
  }

  return (
    <>
      <Card title="Busca">
        <Form {...searchFormProps} layout="inline">
          <Space>
            <Form.Item name="branch_id" label="Código da Filial:">
              <Select {...selectProps} style={{ width: "80px" }} />
            </Form.Item>
            <Button
              size="small"
              type="primary"
              icon={<Icons.SearchOutlined />}
              onClick={searchFormProps.form?.submit}
            >
              Buscar
            </Button>
          </Space>
        </Form>
      </Card>
      <List canCreate={true}>
        <Table {...tableProps} rowKey="HealthInsurerId">
          <Table.Column
            dataIndex="BranchOffice"
            key="BranchOffice"
            title="Id da Area"
            render={(value) => <TextField value={value.AreaId} />}
            defaultSortOrder={getDefaultSortOrder("BranchOffice.AreaId", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="BranchOffice"
            key="BranchOffice"
            title="Filial"
            render={(value) => <TextField value={value.AreaDescription} />}
            defaultSortOrder={getDefaultSortOrder("BranchOffice.AreaDescription", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="BranchOfficePlace"
            key="BranchOffice"
            title="Estado da Filial"
            render={(value) => <TextField value={value?.Uf  ?? 'nulo'} />}
            defaultSortOrder={getDefaultSortOrder("BranchOffice.Uf", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="BranchOfficePlace"
            key="BranchOfficePlace"
            title="Municipio"
            render={(value) => <TextField value={value?.PlaceDescription  ?? 'nulo'} />}
            defaultSortOrder={getDefaultSortOrder("BranchOfficePlace.PlaceDescription", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="BranchOfficePlace"
            key="BranchOfficePlace"
            title="Estado do Municipio"
            render={(value) => <TextField value={value?.Uf  ?? 'nulo'} />}
            defaultSortOrder={getDefaultSortOrder("BranchOfficePlace.Uf", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="BranchOfficePlace"
            key="BranchOfficePlace"
            title="Latitude"
            render={(value) => <TextField value={value?.Latitude  ?? 'nulo'} />}
            defaultSortOrder={getDefaultSortOrder("BranchOfficePlace.Latitude", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="BranchOfficePlace"
            key="BranchOfficePlace"
            title="Longitude"
            render={(value) => <TextField value={value?.Longitude ?? 'nulo'} />}
            defaultSortOrder={getDefaultSortOrder("BranchOfficePlace.Longitude", sorter)}
            sorter
          />
          <Table.Column<ILinkedPlaces>
            title="Ações"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                { record.BranchOfficePlace ? <Button size="small" onClick={() => handleUnbindFilial(record.BranchOfficePlace.Id)}>Desvincular</Button> :  <Button size="small" onClick={() => showModal(record.BranchOffice)}>Vincular</Button>}
                <ShowButton hideText size="small" recordItemId={record.BranchOffice.AreaId} />
                <DeleteButton hidden hideText size="small" recordItemId={record.BranchOffice.AreaId} />
              </Space>
            )}
          />
        </Table>
      </List>
      <Modal
        visible={branchOfficesBindVisible}
        onCancel={branchOfficesBindClose}
        destroyOnClose={true}
        width={840}
        title={"Vincular Municipio a Filial " + branchOffice?.AreaDescription}
        footer={null}
      >
        <ModalBranchOfficeMunicipiesBind 
          branchOffice={branchOffice} 
          closeModal={branchOfficesBindClose} 
          refreshTable={tableQueryResult.refetch}
        />
      </Modal>
    </>
  );
};
